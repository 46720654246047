<template>
  <div class="merchants">
    <PageHeader dilog :border="false" title="企业首营流程" center>
      <Steps :step="1" />
    </PageHeader>
    <!-- 完善企业信息 start  v-if="active === 1"-->
    <div class="Tailoring">
      <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 申请信息</div>
      <el-form
        ref="ruleForm"
        label-position="right"
        :inline="true"
        :rules="rules"
        :model="form"
        size="mini"
        class="demo-form-inline InfoBoxTwo4"
      >
        <el-form-item label="申请日期" prop="ApplyDate">
          <template>
            <div class="m-right-20">{{ form.ApplyDate }}</div>
          </template>
        </el-form-item>
        <el-form-item label="申请部门" prop="ApplyDepartmentID">
          <el-select
            v-model="form.ApplyDepartmentID"
            placeholder="申请部门"
            @change="isBumen"
          >
            <el-option
              v-for="item in PermissionGroup"
              :key="item.IDX"
              :label="item.GroupName"
              :value="item.IDX"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="申请人">
          <el-select
            v-model.number="form.ApplyUserID"
            v-el-select-loadmore="loadmore"
            placeholder="申请人"
          >
            <el-option
              v-for="i in ApplyUserGroup"
              :key="i.UserID"
              :label="i.NickName"
              :value="i.UserID"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="填表人">
          <el-select
            v-model.number="userInfo.NickName"
            disabled
            placeholder="填表人"
          >
            <el-option label="小芳" :value="1" />
            <el-option label="小王" :value="2" />
          </el-select>
        </el-form-item>
        <div class="flex a-center">
          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 企业基本信息
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="客户" />
              <el-checkbox label="供应商" />
            </el-checkbox-group>
            <span style="color: #f56c6c;margin-right:10px"> *</span>
            <el-popover placement="right-end" width="200" trigger="hover">
              <span> 如果即是客户又是供应商的角色，请勾选两项 </span>
              <i slot="reference" class="el-icon-info" style="color: #ccc" />
            </el-popover>
            <span v-if="checkListisSHOW" class="f12 p-left-20" style="color:#f56c6c;font-weight:400">  如果即是客户又是供应商的角色，请勾选两项 </span>
          </div>
        </div>
        <div class="flex j-between a-center">
          <el-form-item label="企业名称" prop="CompanyName">
            <el-input
              v-model="form.CompanyName"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="社会统一信用代码" prop="CreditCode">
            <el-input
              v-model="form.CreditCode"
              maxlength="18"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="企业简称" prop="date">
            <el-input
              v-model="form.CompanyAbbreviation"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
        </div>
        <div class="flex j-between a-center">
          <el-form-item label="企业编号" prop="date">
            <el-input
              v-model="form.CompanyNo"
              size="mini"
              disabled
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="联系人" prop="date">
            <el-input
              v-model="form.ContactName"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="date">
            <el-input
              v-model="form.ContactTel"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
        </div>
        <div class="flex j-between a-center">
          <el-form-item label="传真" prop="date">
            <el-input
              v-model="form.Fax"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="邮箱" prop="date">
            <el-input
              v-model="form.Email"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="法人姓名" prop="date">
            <el-input
              v-model="form.CorporationName"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
        </div>
        <div class="flex j-between a-center">
          <el-form-item label="法人电话" prop="date">
            <el-input
              v-model="form.CorporationTel"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="被授权人" prop="date">
            <el-input
              v-model="form.AuthorizedPerson"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
        </div>
        <el-form-item label="详细地址" prop="Province" size="mini">
          <div class="flex">
            <el-cascader
              v-model="selectedOptions"
              size="mini"
              :options="options1"
              style="width: 290px"
              @change="handleChange"
            />
            <el-input
              v-model="form.Street"
              size="mini"
              style="margin-left: 10px"
              placeholder="请输入内容"
            />
          </div>
        </el-form-item>

        <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
        <div class="InfoBoxTwo4">
          <el-form-item label="开户银行" size="mini">
            <el-input v-model="form.Bank" size="mini" />
          <!-- <el-select v-model="form.Bank" placeholder="请选择" style="width:200px">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
          </el-form-item>
          <el-form-item label="开户支行" size="mini" style="width:66.666%">
            <div class="flex">
              <el-cascader
                v-model="selectedOptions1"
                size="mini"
                :options="options1"
                style="width:150px"
                @change="handleChange1"
              />
              <el-input
                v-model="form.BranchBank"
                size="mini"
                placeholder="支行"
                style="width:calc(100% - 150px); margin-left:5px"
              />
            <!-- <el-select v-model="form.BranchBank" placeholder="支行">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    style="width:200px"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select> -->
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="付款方式" size="mini">
            <el-select
              v-model="form.PayType"
              placeholder="请选择"
            >
              <el-option label="货到付款" value="货到付款" />
              <el-option label="先款后货" value="先款后货" />
              <el-option label="月结" value="月结" />
              <el-option label="先付定金，货到付余款" value="先付定金，货到付余款" />
              <el-option label="其他" value="其他" />
            </el-select>
          <!-- <el-input
                v-model.number="form.PayType"
                style="width:200px"
                size="mini"
                placeholder="请输入内容"
              /> -->
          </el-form-item>
          <el-form-item
            label="回款账期（天）"
            size="mini"
            prop="PaymentCollectionDays"
          >
            <el-input v-model="form.PaymentCollectionDays" type="number" />
          <!-- <el-input-number v-model="form.PaymentCollectionDays" :controls="false" label="请输入天数" style="width: 200px" size="mini" :min="0" /> -->
          </el-form-item>
          <el-form-item label="开票税率" size="mini" prop="TaxRate">
            <el-select
              v-model="form.TaxRate"
              placeholder="请选择"
            >
              <el-option label="17%" :value="0.17" />
              <el-option label="16%" :value="0.16" />
              <el-option label="13%" :value="0.13" />
              <el-option label="11%" :value="0.11" />
              <el-option label="10%" :value="0.10" />
              <el-option label="6%" :value="0.06" />
              <el-option label="3%" :value="0.03" />
              <el-option label="1%" :value="0.01" />
              <el-option label="0%" :value="0" />
              <el-option label="   " :value="-1" disabled />
            </el-select>
          <!-- <el-input
                v-model.number="form.TaxRate"
                style="width:200px"
                size="mini"
                placeholder=""
                type="number"
              />% -->
          </el-form-item>
        </div>
        <el-form-item label="备注" size="mini" prop="date">
          <el-input
            v-model="form.Remark"
            size="mini"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <div>
        <PageHeader title="已上传的文件">
          <!-- :disabled="info.IDX || IDX ? false :true " -->
          <el-button size="mini" @click="uoLoad">上传证件</el-button>
          <el-button size="mini" type="danger" plain @click="delImgs(1, false)">批量删除</el-button>
        </PageHeader>
        <div class="main_flex flex j-between min-border-bottom">
          <div class="left flex a-center p-tb-20  min-border-right">
            <div
              v-for="(i, index) in tableData1"
              :key="index"
              style="position: relative"
              class="img_view m-tb-20"
            >
              <img :src="imgsUrl + i.PhotoUrl" @click="showPic">
              <div
                v-if="!isStaticPic"
                style="position: absolute; right: 5px; top: 5px; z-index: 10"
                class="cursor"
                @click="delImgs(i, true)"
              >
                x
              </div>
            </div>
          </div>
          <div class="right">
            <div class="p-lr-20  m-tb-20">
              <el-table
                ref="multipleTable"
                :border="false"
                :data="tableData1"
                style="border:none"
                height="400px"
                tooltip-effect="dark"
                @select-all="selectAll1"
                @select="select1"
              >
                <af-table-column type="selection" width="55" />
                <af-table-column
                  prop="TypeString"
                  label="证件类型"
                  width="180"
                />
                <af-table-column
                  prop="CompanyName"
                  label="企业名称"
                  width="180"
                />
                <af-table-column prop="CertificateCode" label="证件编号" width="250" />
                <af-table-column prop="ValidToString" label="证件有效期" />
                <af-table-column prop="Status" label="状态" />
                <af-table-column v-if="!isStaticPic" prop="address" label="操作" fixed="right" width="50">
                  <template slot-scope="scope">
                    <el-popover
                      placement="left"
                      trigger="hover"
                      :width="50"
                      popper-class="test_pop_view"
                    >
                      <div>
                        <div
                          class="cursor"
                          @click="editImgs(scope.row)"
                        >
                          <i class="el-icon-edit p-right-10" />
                          <span>编辑</span>
                        </div>
                        <div
                          class="cursor"
                          @click="delImgs(scope.row, true)"
                        >
                          <i class="el-icon-delete p-right-10" />
                          <span>删除</span>
                        </div>
                        <div
                          class="cursor"
                          @click="downLoadImgs(scope.row)"
                        >
                          <i class="el-icon-download p-right-10" />
                          <span>下载</span>
                        </div>
                      </div>
                      <i slot="reference" class="el-icon-more" />
                    </el-popover>
                  </template>
                </af-table-column>
                <div slot="empty">
                  <p :style="{ marginTop: '10px' }">表格数据为空</p>
                </div>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="caozuo t-right p-top-20">
        <el-button :loading="btnLoadiung1" @click="save1">保存草稿</el-button>
        <el-button type="primary" :loading="btnLoadiung" @click="LaunchApproval1">{{ view_item.Status === 3 ? "重新发起" : "发起审核" }}</el-button>
      </div>
    </div>
    <Uploads
      ref="Uploads"
      :idx="IDX"
      :form="info"
      :first-camp-company-i-d="firstCampCompanyID"
      :types="types"
      :show="dialogTableVisible1"
      @save="saveInfo"
      @cancel="cancels"
    />
    <el-image-viewer
      v-if="showViewer"
      style="z-index: 9999"
      :url-list="MaterialFileList"
      :on-close="() => (showViewer = false)"
    />
  </div>

  <!-- 完善企业信息 end -->
</template>

<script>
import {
  regionData,
  CodeToText,
  TextToCode
} from 'element-china-area-data'
import PageHeader from '@/components/PageHeader.vue'
import Steps from '@/components/steps'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import $api from '@/request/productApis.js'
import Uploads from '@/components/uploads'

// import Index from '@/minxin/merchants/index'
export default {
  name: 'NewCompy',
  components: {
    PageHeader,
    Steps,
    Uploads,
    ElImageViewer
  },
  directives: {
    'el-select-loadmore': {
      inserted(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      form: {
        IDX: 0,
        ApplyDate: this.$minCommon.formatDate(new Date(), 'yyyy/MM/dd'),
        ApplyDepartmentID: '',
        ApplyUserID: '',
        CompanyType: 1,
        CompanyName: '',
        CreditCode: '',
        CompanyAbbreviation: '',
        CompanyNo: '',
        ContactName: '',
        ContactTel: '',
        Fax: '',
        Email: '',
        CorporationName: '',
        CorporationTel: '',
        AuthorizedPerson: '',
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Bank: '',
        BranchBankProvince: '',
        BranchBankCity: '',
        BranchBankArea: '',
        BranchBank: '',
        PayType: '',
        PaymentCollectionDays: '',
        TaxRate: '',
        Remark: '',
        CompanyCertList: [] // 暂存的证照
      },
      rules: {
        CompanyName: [{
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        }],
        CompanyType: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.form.CompanyType === 0) {
              callback(new Error('请选择企业类型'))
            }
            callback()
          },
          trigger: 'blur'
        }],
        CreditCode: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.form.CreditCode) {
              callback(new Error('请输入社会信用统一代码'))
            }
            if (this.form.CreditCode) {
              if (this.form.CreditCode.length !== 18) callback(new Error('请输入18位社会信用统一代码'))
              // const regNumber = /\d+/ // 验证0-9的任意数字最少出现1次。
              // const regString = /[A-Z]+/ // 验证大小写26个字母任意字母最少出现1次。
              const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
              // 验证第一个字符串
              if (reg.test(this.form.CreditCode)) {
                console.log('str1：验证成功')
              } else {
                callback(new Error('请输入正确的社会信用统一代码'))
              }
            }
            callback()
          },
          trigger: 'blur'
        }],
        Province: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.form.Province === '' || this.form.City === '' || this.form.Area === '' || this.form.Street === '') {
              callback(new Error('请输入详细地址'))
            }
            callback()
          },
          trigger: 'blur'
        }]
      },
      imgsUrl: this.b2bstaticUrl,
      firstCampCompanyID: '',
      showViewer: false,
      PermissionGroup: [],
      checkList: [],
      userInfo: {},
      types: [],
      dialogTableVisible1: false,
      info: {},
      view_item: {},
      ApplyUserGroup: [],
      ApplyUserPage: 1,
      btnLoadiung: false,
      btnLoadiung1: false,
      ApplyUserPageSize: 15,
      checkListisSHOW: false,
      selectedOptions: [],
      isStaticPic: false,
      selectedOptions1: [],
      options1: regionData,
      tableData1: [],
      MaterialFileList: [],
      IDX: ''
    }
  },
  mounted() {
    console.log(this.$route.params)
    if (this.$route.params.form) {
      // this.form.CompanyCertList = Array.isArray(this.$route.params.form.CompanyCertList) ? this.$route.params.form.CompanyCertList : []
      this.form = this.$route.params.form
      this.form.CompanyCertList = []
      this.firstCampCompanyID = this.form.IDX
      this.IDX = this.$route.params.IDX
      this.setType(this.form.CompanyType)
      this.selectedOptions = [TextToCode[ this.form.Province].code, TextToCode[ this.form.Province][this.form.City].code, TextToCode[ this.form.Province][ this.form.City][ this.form.Area].code]
      if (this.form.BranchBankProvince) {
        this.selectedOptions1 = [TextToCode[ this.form.BranchBankProvince].code, TextToCode[ this.form.BranchBankProvince][ this.form.BranchBankCity].code, TextToCode[ this.form.BranchBankProvince][ this.form.BranchBankCity][ this.form.BranchBankArea].code]
      }
      this.$api.GetFirstCampCompanyCertificateList({
        firstCampCompanyID: this.IDX
      }).then(res => {
        console.log(res)
        this.form.CompanyCertList = []
        this.form.CompanyCertList = Array.isArray(res.Message) ? res.Message : []
        this.tableData1 = this.form.CompanyCertList
      })
    }
    this.$api.PermissionGroupInfo().then(res => {
      this.PermissionGroup = res.Message
      this.getUserInfo()
    })
  },
  methods: {
    // 展示图片预览
    showPic() {
      this.MaterialFileList = []
      this.showViewer = true
      this.tableData1.forEach(item => {
        this.MaterialFileList.push(`${this.imgsUrl}${item.PhotoUrl} `)
      })
      // this.MaterialFileList.push(src)
    },
    // 设置修改默认的显示
    setType(type) {
      if (type === 4) {
        return this.checkList = ['客户', '供应商']
      }
      if (type === 1) {
        return this.checkList = ['客户']
      }
      if (type === 2) {
        return this.checkList = ['供应商']
      }
    },
    // 证照上传成功的保存回调
    saveInfo(info) {
      console.log(info)
      const obj = {
        IDX: 0,
        FirstCampCompanyID: info.firstCampCompanyID ? info.firstCampCompanyID : 0,
        CertificateCode: info.form.CertificateCode,
        TypeID: info.form.Code,
        PhotoUrl: '',
        ValidTo: info.form.ValidTo,
        IsLongRange: info.form.IsLongRange ? 1 : 0,
        CertificateName: info.form.CertificateName,
        CompanyName: info.form.CompanyName,
        Remark: info.form.Remark,
        Address: info.form.Address,
        TypeString: info.form.CertificateName,
        ValidToString: '',
        Status: 0
      }
      // 先获取URL
      this.$api.SaveFirstCampCompanyCertificate({
        Base64String: info.base64,
        FileExtName: info.form.FileExtName
      }).then(res => {
        console.log(res.Message.Url)
        obj.PhotoUrl = res.Message.Url
        obj.IDX = res.IDX
        console.log('this.form.CompanyCertList', this.form.CompanyCertList)
        this.form.CompanyCertList.push(obj)
        // 预览图片 将图片静态展现在列表里
        this.tableData1 = this.form.CompanyCertList
        console.log('dsaasddassad', this.tableData1)
        // TypeString   ValidToString
        this.tableData1.map(item => {
          item.ValidToString = this.$minCommon.formatDate(new Date(item.ValidTo), 'yyyy-MM-dd')
        })
        this.isStaticPic = true
        console.log('asddsadsa', this.form.CompanyCertList)
      })
    },
    // 加载更多
    loadmore() {
      // console.log(e)
      // this.ApplyUserPage++
      this.ApplyUserPage++
      // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
      const _ApplyDepartmentID = (this.form.ApplyDepartmentID === null || this.form.ApplyDepartmentID.length < 1) ? -1 : this.form.ApplyDepartmentID
      this.GetApplyUserInfo(_ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize, '').then(response => {
        this.ApplyUserGroup = this.ApplyUserGroup.concat(response.Message)
        console.log(response)
      })
    },
    // 申请部门
    // update 20210608 phalange 新增 name
    async GetApplyUserInfo(permissionGroupInfoId, pageIndex, pageSize, name) {
      return await this.$api.GetApplyUserInfo({
        permissionGroupInfoId,
        pageIndex,
        pageSize,
        name
      })
    },
    uoLoad() {
      this.dialogTableVisible1 = true
      // const IDX = this.info.IDX
      this.info = {
        IsLongRange: false,
        CertificateName: '',
        CompanyName: this.form.CompanyName
      }
      // this.info.IsLongRange = false
      // this.IDX = ''
      this.info.IDX = this.IDX
      this.GetCertificateType()
    },
    // 获取当前信息
    getUserInfo() {
      $api.getUserInfo().then(res => {
        console.log('dsaasdasd', res)
        this.userInfo = res.Message
        // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
        this.form.ApplyDepartmentID = res.Message.PermissionGroupID * 1 ? res.Message.PermissionGroupID * 1 : ''
        const _ApplyDepartmentID = res.Message.PermissionGroupID * 1 ? res.Message.PermissionGroupID * 1 : -1
        this.GetApplyUserInfo(_ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize, '').then(response => {
          this.ApplyUserGroup = response.Message
        })
        console.log('this.form.ApplyDepartmentID', this.form.ApplyDepartmentID)
        this.form.ApplyUserID = res.Message.UserID ? res.Message.UserID : ''

        this.form.FillFormUserID = res.Message.UserID ? res.Message.UserID : ''
        console.log(' this.form.FillFormUserID', this.form.FillFormUserID)
      })
    },
    handleChange(value) {
      this.form.Province = CodeToText[value[0]]
      this.form.City = CodeToText[value[1]]
      this.form.Area = CodeToText[value[2]]
    },
    handleChange1(value) {
      this.form.BranchBankProvince = CodeToText[value[0]]
      this.form.BranchBankCity = CodeToText[value[1]]
      this.form.BranchBankArea = CodeToText[value[2]]
    },
    delImgs(row, isAll) {
      console.log(row)
      if (!isAll && this.ImgsListSecltStr === '') return this.$message.warning('请选择你要操作的数据')
      this.$confirm('确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteFirstCampCompanyCert({
          IDXList: isAll ? row.IDX + '' : this.ImgsListSecltStr
        }).then(res => {
          if (res.RetCode === '0') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.$api.GetFirstCampCompanyCertificateList({
              firstCampCompanyID: this.firstCampCompanyID
            }).then(res => {
              this.tableData1 = res.Message
            })
          }
        })
      })
    },
    cancels(e) {
      this.dialogTableVisible1 = e
      if (this.firstCampCompanyID) {
        this.$api.GetFirstCampCompanyCertificateList({
          firstCampCompanyID: this.firstCampCompanyID
        }).then(res => {
          console.log(res)
          this.tableData1 = res.Message
        })
      }
    },
    selectAll1(e) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(e)
      console.log(this.ImgsListSeclt)
    },
    select1(row) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(row)

      console.log(this.ImgsListSeclt)
    },
    GetCertificateType() {
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        console.log(res)
        this.types = res.Message
      })
    },

    editImgs(row) {
      this.IDX = row.IDX + ''
      this.dialogTableVisible1 = true
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        this.types = res.Message
      })
      this.$api.GetFirstCampCertificateInfo({
        IDX: this.IDX
      }).then(res => {
        console.log(res)
        this.info = res.Message
        this.info.IsLongRange === 1 ? this.info.IsLongRange = true : this.info.IsLongRange = false
        this.info.IsLongRange ? this.info.ValidTo = '' : ''
        this.$refs.Uploads.option.img = `${this.b2bstaticUrl}${this.info.PhotoUrl}`
      })
    },
    isBumen(e) {
      console.log(e)
      this.form.ApplyUserID = ''
      // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
      const _ApplyDepartmentID = (this.form.ApplyDepartmentID === null || this.form.ApplyDepartmentID.length < 1) ? -1 : this.form.ApplyDepartmentID
      this.GetApplyUserInfo(_ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize, '').then(response => {
        this.ApplyUserGroup = response.Message
      })
    },
    downLoadImgs(row) {
      console.log(row.PhotoUrl.split('.')[1])
      this.$api.GetFirstCampFiles({
        FileURL: this.b2bstaticUrl + row.PhotoUrl
      }).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.' + row.PhotoUrl.split('.')[1])
      })
    },
    save1() {
      if (this.checkList.length === 0) {
        this.checkListisSHOW = true
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.checkList.length === 0) return
          this.btnLoadiung1 = true
          this.form.IDX = this.IDX || ''
          if (this.firstCampCompanyID) {
            this.form.IDX = this.firstCampCompanyID
          }
          if (this.form.TaxRate === '') {
            this.form.TaxRate = -1
          }
          if (this.$route.params.TYPE === '1') {
            this.$api.SaveCompanySupplier(this.form).then(res => {
              console.log('保存客商', res)
              this.btnLoadiung1 = false
              this.checkListisSHOW = false
              this.firstCampCompanyID = res.Message // 企业ID IDX
              if (res.RetCode === '0') {
                this.handleCurrentChange(1)
                this.selectedOptions = []
                this.selectedOptions1 = []
                return
              }
              this.$message.error(res.RetMsg)
            })
            return this.btnLoadiung1 = false
          }
          this.form.OperType = 1 // 保存草稿
          if (Array.isArray(this.form.CompanyCertList)) {
            if (this.form.CompanyCertList.length <= 0) {
              this.form.CompanyCertList = null
            } else {
              this.form.CompanyCertList = JSON.stringify(this.form.CompanyCertList)
            }
          } else {
            this.form.CompanyCertList = null
          }
          this.$api.SaveFirstCampCompanyInfo(this.form).then(res => {
            this.checkListisSHOW = false
            this.btnLoadiung1 = false
            this.firstCampCompanyID = res.Message // 企业ID IDX
            if (res.RetCode === '0') {
              // this.active = 0
              this.$message.success('保存成功')
              setTimeout(() => {
                this.$router.push({
                  name: 'Merchants1',
                  params: { isCache: 6 }
                })
              }, 1000)

              // return this.handleCurrentChange(1)
            }
            this.$message.error(res.RetMsg)
            return this.btnLoadiung1 = false
          })
          this.selectedOptions = []
          this.selectedOptions1 = []
        }
      })
    },
    // 发起审核
    LaunchApproval1() {
      // 发起审核之前先保存 wait
      console.log('this.params', this.$route.params)
      console.log(this.checkList)
      if (this.checkList.length === 0) {
        this.checkListisSHOW = true
      }
      console.log(this.checkList)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.checkList.length === 0) return
          this.btnLoadiung = true
          this.form.IDX = this.IDX || ''
          if (this.firstCampCompanyID) {
            this.form.IDX = this.firstCampCompanyID
          }
          if (this.form.TaxRate === '') {
            this.form.TaxRate = -1
          }
          if (this.$route.params.TYPE === '1') {
            // this.form.SourceType = 2 // 客商
            this.$api.SaveCompanySupplier(this.form).then(res => {
              console.log('保存客商', res)
              this.btnLoadiung = false
              this.checkListisSHOW = false
              this.firstCampCompanyID = res.Message // 企业ID IDX
              if (res.RetCode === '0') {
                this.$api.GetFirstCampCompanyInfo({
                  IDX: res.Message
                }).then(res1 => {
                  this.info = res1.Message
                  this.info_temp = res1.Message
                })
                this.$api.GetCompanySupplierCertList({
                  SupplierID: this.info.SupplierID
                }).then(res => {
                  this.tableData1 = res.Message
                })
                this.selectedOptions = []
                this.selectedOptions1 = []
                return
              }
              this.$message.error(res.RetMsg)
            })
            return this.btnLoadiung = false
          }
          // this.form.SourceType = 1 // 首营
          this.form.OperType = 2 // 发起审核
          if (Array.isArray(this.form.CompanyCertList)) {
            if (this.form.CompanyCertList.length <= 0) {
              this.form.CompanyCertList = null
            } else {
              this.form.CompanyCertList = JSON.stringify(this.form.CompanyCertList)
            }
          } else {
            this.form.CompanyCertList = null
          }

          this.$api.SaveFirstCampCompanyInfo(this.form).then(res => {
            this.checkListisSHOW = false
            this.btnLoadiung = false
            this.firstCampCompanyID = res.Message // 企业ID IDX
            if (res.RetCode === '0') {
              this.$message.success('发起审核成功')
              setTimeout(() => {
                // this.active = 0
                this.$router.push({
                  name: 'Merchants1',
                  params: { isCache: 6 }
                })
                // return this.handleCurrentChange(1)
              }, 1000)
              return
            }
            this.$message.error(res.RetMsg)
            return this.btnLoadiung = false
          })
          this.selectedOptions = []
          this.selectedOptions1 = []
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
//   mixins: [Index]
}
</script>
<style scoped lang='scss'>
@import "@/style/search.scss";
@import "@/style/business.scss";
@import '@/style/product.scss';
</style>
