import { render, staticRenderFns } from "./newCompy.vue?vue&type=template&id=f333f022&scoped=true&"
import script from "./newCompy.vue?vue&type=script&lang=js&"
export * from "./newCompy.vue?vue&type=script&lang=js&"
import style0 from "./newCompy.vue?vue&type=style&index=0&id=f333f022&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f333f022",
  null
  
)

export default component.exports